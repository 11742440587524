
import { Connector } from "@evercam/shared/types"
export default {
  props: {
    details: {
      type: [Object, null],
      default: () => null,
    },
  },
  compuetd: {
    hostnameLabel() {
      return this.details.provider === Connector.VoyageControl
        ? "Venue:"
        : "Hostname:"
    },
  },
}
