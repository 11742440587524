
import Aconex from "@/components/connectors/Aconex"
import ContactUs from "@/components/connectors/ContactUs"
import Procore from "@/components/connectors/Procore"
import Autodesk from "@/components/connectors/Autodesk"
import VoyageControl from "@/components/connectors/VoyageControl.vue"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  meta: {
    pageId: AnalyticsEventPageId.Connectors,
  },
  components: {
    VoyageControl,
    Procore,
    Aconex,
    ContactUs,
    Autodesk,
  },
  head() {
    return {
      title: `Evercam | ${this.$t("pages.connectors")}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
